<template>
	<div class="video_wrapper" :ref="uuid + '_wrapper'">
		<div class="video_content">
			<div class="video_header" v-if="showTitle && getTitle">
				<div class="video_title">{{ getTitle }}</div>
				<i v-if="close" class="video_close el-icon-close" @click="closeCur"></i>
			</div>
			<div class="video_info" :style="{ top: getTitle ? '40px' : '0px' }">
				<div class="video_info_left">
					<h2 class="video_speed" v-if="isHistory && getCurrentSpeed && !isTalk">{{ getCurrentSpeed }}</h2>
				</div>
				<div class="video_info_right">
					<h2 class="video_network" v-if="showNetwork && !isTalk">{{ getCurrentNetwork }}</h2>
					<h2 class="video_buffer" v-if="showDelay && !isHistory">{{ bufferTime }}</h2>
				</div>
			</div>
			<div class="video_container">
				<video
					:controls="showControls"
					:id="uuid"
					preload="load"
					crossorigin="anonymous"
					@contextmenu="
						e => {
							e.preventDefault();
						}
					"
				></video>
				<div
					class="video_loading"
					v-if="loading"
					v-loading="loading"
					element-loading-text="视频加载中，请稍后..."
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.6)"
				></div>
				<div class="video_tips" v-if="showTips">
					<h2>{{ getTipsWords }}</h2>
				</div>
			</div>
			<div
				class="video_control"
				:class="isLinePage ? 'line-page' : ''"
				v-if="hasControl && isSupport"
				@mousedown="sliderMouseDown"
			>
				<el-slider
					v-if="showProgress"
					:disabled="!isPlay"
					:debounce="800"
					v-model="sliderCurrent"
					:max="gapTime"
					class="control_progress"
					:format-tooltip="formatCurrentTooltip"
					@change="changeCurrentTime"
				></el-slider>
				<div class="control_func">
					<div class="control_left">
						<i class="iconfont icon-zanting" v-if="isPlay" @click="pauseVideo"></i>
						<i class="iconfont icon-kaishi" v-else-if="!isTalk" @click="playVideo"></i>
						<template v-if="!isLinePage">
							<common-popper ref="volume" v-if="!isTalk">
								<el-slider
									class="volume"
									style="margin: 10px 0"
									slot="content"
									v-model="volume"
									@change="changeVolume"
									vertical
									height="100px"
								></el-slider>
								<i slot="reference" class="iconfont icon-shengyin"></i>
							</common-popper>
							<i
								title="快退"
								class="iconfont icon-kuaitui"
								v-if="isHistory && showSpeedControl && speedControl"
								@click="changeSlower"
							></i>
							<i
								title="快进"
								class="iconfont icon-yixianshi-"
								v-if="isHistory && showSpeedControl && speedControl"
								@click="changeFaster"
							></i>
							<i
								class="iconfont icon-talkback_fill"
								:title="isTalk ? '关闭对讲' : '开始对讲'"
								v-if="showTalk"
								@click="needTalkPermission"
								:style="{ color: isTalk ? 'yellow' : 'white' }"
							></i>
						</template>
					</div>
					<div class="control_right">
						<template v-if="!isLinePage">
							<select v-model="channelId" v-if="showChannel && channel" @change="changeChannelId">
								<option v-for="i in 16" :key="i" :value="i">通道{{ i }}</option>
							</select>
							<common-popper ref="mode" trigger="click" v-if="showMode && !isHistory && mode">
								<ul slot="content">
									<li v-for="item in modeList" :key="item.value" @click="changeModeType(item.value)">{{ item.name }}</li>
								</ul>
								<el-button type="text" slot="reference">{{ modeList[getCurrentMode].name }}</el-button>
							</common-popper>
							<common-popper ref="stream" trigger="click" v-if="showStream && stream">
								<ul slot="content">
									<li v-for="item in streamList" :key="item.value" @click="changeStreamType(item.value)">{{ item.name }}</li>
								</ul>
								<el-button type="text" slot="reference">{{ streamList[getCurrentStream].name }}</el-button>
							</common-popper>
							<i title="截取当前视频帧" class="iconfont icon-camera" @click="snapShot"></i>
							<i
								class="iconfont"
								:class="isRecord ? 'icon-guanbiluzhi' : 'icon-kaiqiluzhi'"
								:title="isRecord ? '关闭录制' : '开始录制'"
								@click="openRecord"
								:style="{ color: isRecord ? 'yellow' : 'white' }"
							></i>
							<i class="iconfont icon-quanping" @click="fullScreen"></i>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector";
import { getUUID, macTimeToStamp, stampToFullTime, getTime } from "./utils";
import Player from "./play.js";
import WebRecorder from "./recorder.js";
import CommonPopper from "./common_popper";

export default {
	methods: {
		playVideo() {
			this.player.playVideo();
		},
		pauseVideo() {
			// 目前暂停暂时相当于停止播放
			this.player.stopVideo();
			this.bufferTime = 0;
			// this.player.pauseVideo();
		},
		stopVideo() {
			this.player.stopVideo();
			this.bufferTime = 0; // 缓冲区剩余未播放的时间
			if (this.isHistory) {
				// 重置进度条
				this.sliderCurrent = 0;
				this.baseTime = 0; // 拖动的基础时间
				this.currentTime = 0; // 视频播放的时间
			}
		},
		resize(width, height) {
			this.height = height;
			// 更新码流悬浮窗的位置
			this.$refs.volume && this.$refs.volume.update();
			this.$refs.stream && this.$refs.stream.update();
			this.$refs.mode && this.$refs.mode.update();
			// 根据高度自适应字体
			// 横向
			if (width >= 330) {
				// 都显示
				this.showChannel = true;
				this.showStream = true;
				this.showSpeedControl = true;
				this.showMode = true;
			} else if (width >= 280) {
				// 不显示修改播放模式
				this.showChannel = true;
				this.showStream = true;
				this.showSpeedControl = true;
				this.showMode = false;
			} else if (width >= 200) {
				// 只显示快进快退
				this.showChannel = false;
				this.showMode = false;
				this.showStream = false;
				this.showSpeedControl = true;
			} else {
				// 都不显示
				this.showChannel = false;
				this.showSpeedControl = false;
				this.showStream = false;
				this.showMode = false;
			}
			// 纵向
			if (height >= 300) {
				this.showTitle = true;
				this.showDelay = true;
				this.showNetwork = true;
			} else {
				this.showTitle = false;
				this.showDelay = false;
				this.showNetwork = false;
			}
			// this.vHeight = height - 85;
			// this.vWidth = width;
		},
		changeStreamType(command) {
			this.player && this.player.changeStream(command);
		},
		changeModeType(mode) {
			this.player.changeMode(mode);
		},
		fullScreen() {
			this.player.toggleScreen();
		},
		changeVolume(number) {
			this.player.setVolume(number);
		},
		snapShot() {
			this.player.snapShot();
		},
		changeFaster() {
			this.player.changeFaster();
		},
		changeSlower() {
			this.player.changeSlower();
		},
		openRecord() {
			if (!this.isRecord) {
				if (this.isPlay) {
					this.player.startRecord();
					this.isRecord = true;
				}
			} else {
				this.player.endRecord();
				this.isRecord = false;
			}
		},
		// 若有多个视频窗口，则由父组件控制是否能对讲
		needTalkPermission() {
			if (this.isTalk) {
				this.closeTalk();
			}
			this.$emit("talk", this.uuid, this.openTalk.bind(this));
		},
		openTalk() {
			if (this.recorder) {
				this.recorder.begin();
				this.isTalk = true;
			} else {
				this.$message.error({
					message: "浏览器不支持音频输入",
					duration: 3000
				});
			}
		},
		closeTalk() {
			this.recorder && this.recorder.stop();
			this.isTalk = false;
		},
		// 进度条
		formatCurrentTooltip(val) {
			if (this.isHistory) {
				return new Date(val + this.minTime).Format("hh:mm:ss");
			} else {
				return getTime(val);
			}
		},
		findClassName(paths, className) {
			return !!paths.find(path => {
				return (
					path && path.tagName === "DIV" && path.getAttribute("class") && path.getAttribute("class").indexOf(className) !== -1
				);
			});
		},
		sliderMouseDown(e) {
			if (
				e.target.className.indexOf("slider") != -1 &&
				e.target.className.indexOf("button") != -1 &&
				this.findClassName(e.path, "control_progress")
			) {
				const videoEl = document.getElementById(this.uuid);
				videoEl.pause();
			}
		},
		changeCurrentTime(val) {
			if (this.isHistory) {
				this.baseTime = val;
				this.player.seekCurrent(stampToFullTime(macTimeToStamp(this.startTime) + val));
			}
		},
		destroy() {
			if (this.erd) {
				this.erd.uninstall(this.$refs[this.uuid + "_wrapper"]);
				this.erd = null;
			}
			if (this.recorder) {
				this.recorder.destrory();
				this.recorder = null;
				this.isTalk = false;
			}
			if (this.player) {
				this.player.destroy();
				this.player = null;
			}
		},
		// hhmmss ==> hh:mm:ss
		getTimeStr(timeStr) {
			const hh = timeStr.substring(0, 2);
			const mm = timeStr.substring(2, 4);
			const ss = timeStr.substring(4);
			return hh + ":" + mm + ":" + ss;
		},
		changeChannelId(id) {
			if (!(id instanceof Event)) {
				if (this.channelId !== id) {
					this.channelId = id;
				} else {
					return;
				}
			}
			this.player && this.player.setChannelId(this.channelId);
			this.recorder && this.recorder.setChannelId(this.channelId);
		},
		changeDeviceId(id) {
			if (!(id instanceof Event)) {
				if (this.clientId !== id) {
					this.clientId = id;
				} else {
					return;
				}
			}
			this.player && this.player.setDeviceId(this.clientId);
			this.recorder && this.recorder.setDeviceId(this.clientId);
			return this.queryHost();
		},
		closeCur() {
			this.$emit("close");
		},
		queryHost() {
			return this.$http
				.post(
					"/video/getVideoStreamServerAddr",
					{ clientId: this.clientId },
					{
						type: "format"
					}
				)
				.then(res => {
					const host = res.detail;
					this.player.setHost(host.IP, host.port);
				});
		}
	},
	data() {
		return {
			player: null,
			uuid: "",
			// 监听video wrapper变化
			erd: null,
			// 最外层宽度
			height: 0,
			volume: 0,
			// 显示码流切换
			showStream: true,
			// 显示播放模式：流畅 实时
			showMode: true,
			// 显示快进 快退
			showSpeedControl: true,
			// 显示通道
			showChannel: true,
			// 显示标题
			showTitle: true,

			// 默认通道1
			channelId: 1,
			// 设备id
			clientId: "",

			// 显示网速
			showNetwork: true,

			// 视频加载进度条
			loading: false,

			// 是否处于双向对讲状态
			isTalk: false,
			recorder: null,
			isRecord: false,

			streamList: [
				{
					name: "主码流",
					value: 0
				},
				{
					name: "子码流",
					value: 1
				}
			],
			modeList: [
				{
					name: "流畅",
					value: 0
				},
				{
					name: "实时",
					value: 1
				}
			],

			baseTime: 0, // 拖动的基础时间
			currentTime: 0, // 视频播放的时间
			bufferTime: 0, // 缓冲区剩余未播放的时间
			showDelay: true, // 是否显示缓冲时间
			sliderCurrent: 0,

			supportFlv: false,

			vWidth: 0, // 动态改变video的宽度
			vHeight: 0, // 动态改变video的高度

			beforeFullSize: {
				width: 0,
				height: 0
			}
		};
	},
	watch: {
		currentTime() {
			this.sliderCurrent = this.baseTime + this.currentTime;
		},
		isHistory() {
			// 更改历史模式
			this.player && this.player.setIsHistory(this.isHistory);
		},
		endTime() {
			this.player && this.player.setTimeRange("", this.endTime);
		},
		startTime() {
			this.player && this.player.setTimeRange(this.startTime, "");
		}
	},
	computed: {
		isLinePage() {
			return this.fromPage === "line";
		},
		isSupport() {
			return !!(this.clientId && this.channelId) && this.supportFlv;
		},
		isPlay() {
			return this.player && this.player.isPlay;
		},
		isFull() {
			return this.player && this.player.isFull;
		},
		getCurrentStream() {
			return (this.player && this.player.codeType) || 0;
		},
		getCurrentMode() {
			return (this.player && this.player.modeType) || 0;
		},
		getCurrentSpeed() {
			const speed = this.player && this.player.currentSpeed;
			if (speed) {
				if (speed > 0) {
					return "快进:" + speed + "倍";
				} else {
					return "快退:" + Math.abs(speed) + "倍";
				}
			} else {
				return 0;
			}
		},
		getCurrentNetwork() {
			if (this.player && this.player.network) {
				if (this.player.network >= 1024) {
					return (this.player.network / 1024).toFixed(1) + "m/s";
				} else {
					return this.player.network + "kb/s";
				}
			} else {
				return "0kb/s";
			}
		},
		showPlayRate() {
			return this.isHistory && this.startTime && this.endTime;
		},
		showControls() {
			return process.env.NODE_ENV === "development";
		},
		showTalk() {
			return !this.isPlay && !this.isHistory && this.canTalk;
		},
		getTitle() {
			if (this.vtitle) {
				return this.vtitle;
			} else {
				if (this.isHistory) {
					return this.getTimeStr(this.startTime.substring(8)) + "--" + this.getTimeStr(this.endTime.substring(8));
				} else {
					return "";
				}
			}
		},
		showTips() {
			return this.isTalk || !this.clientId || !this.supportFlv || !this.clientId;
		},
		getTipsWords() {
			if (!this.clientId) {
				return "设备无信号";
			} else if (this.isTalk) {
				return "双向对讲中...";
			} else if (!this.supportFlv) {
				return "浏览器不支持flv格式";
			} else {
				return "设备无信号";
			}
		},
		showProgress() {
			return this.isHistory || this.showProgressWhenRT;
		},
		minTime() {
			if (this.isHistory && this.startTime) {
				return macTimeToStamp(this.startTime);
			} else {
				return 0;
			}
		},
		maxTime() {
			if (this.isHistory && this.endTime) {
				return macTimeToStamp(this.endTime);
			} else {
				return 0;
			}
		},
		gapTime() {
			return this.maxTime - this.minTime;
		}
	},
	props: {
		fromPage: String,
		vtitle: String,
		hasControl: {
			type: Boolean,
			default: true
		},
		// 历史模式控制是否可以快进或者快退播放
		isHistory: {
			type: Boolean,
			default: false
		},
		// 接口请求必备的参数
		params: Object,
		autoPlay: {
			type: Boolean,
			default: true
		},
		// flvjs播放配置列表
		flvConfig: Object,
		// 播放器默认的配置列表，清晰度 播放模式 默认音量
		PlayerConfig: {
			type: Object,
			default() {
				return {
					volume: 0,
					mode: 0,
					stream: 0,
					// 1:根据查询的资源列表动态的调整开始时间进行播放
					// 2:不使用资源列表调整
					historyStrategy: 1
				};
			}
		},

		// 历史模式视频文件播放的时间间隔,若要显示播放进度条则必传，格式为 210507105123 -> 2021-05-07 10:51:23
		startTime: String,
		endTime: String,
		// 默认可以双向对讲
		canTalk: {
			type: Boolean,
			default: true
		},
		// 是否显示码流
		stream: {
			type: Boolean,
			default: true
		},
		// 是否显示播放模式
		mode: {
			type: Boolean,
			default: true
		},
		// 是否显示通道
		channel: {
			type: Boolean,
			default: true
		},
		// 是否显示历史快进快退
		speedControl: {
			type: Boolean,
			default: true
		},
		// 实时模式下是否显示进度条
		showProgressWhenRT: {
			type: Boolean,
			default: false
		},
		close: {
			type: Boolean,
			default: false
		}
	},
	created() {
		this.uuid = getUUID();
		const { clientId, channelId } = this.params;
		this.clientId = clientId;
		this.channelId = channelId;
		this.supportFlv = Player.isSupported();
	},
	mounted() {
		if (this.supportFlv) {
			this.erd = elementResizeDetectorMaker();
			const rootNode = this.$refs[this.uuid + "_wrapper"];
			this.erd.listenTo(rootNode, element => {
				this.resize(element.offsetWidth, element.offsetHeight);
			});
			let url = this.clientId + "-" + this.channelId;
			this.player = new Player(this, this.uuid, url, this.flvConfig, this.PlayerConfig);
			this.player.setOption(this.params);
			this.player.setIsHistory(this.isHistory);
			this.player.setTimeRange(this.startTime, this.endTime);
			if (this.clientId) {
				this.$http
					.post(
						"/video/getVideoStreamServerAddr",
						{ clientId: this.clientId },
						{
							type: "format"
						}
					)
					.then(res => {
						const host = res.detail;
						this.player.setHost(host.IP, host.port);
						if (this.autoPlay && this.clientId && this.channelId) {
							this.player.playVideo();
						}
					});
			}
		}

		if (WebRecorder.isSupported()) {
			this.recorder = new WebRecorder(this.params);
		}

		window.addEventListener("beforeunload", () => {
			this.destroy();
		});

		window.addEventListener("fullscreenchange", () => {
			if (!document.fullscreenElement && this.player) {
				// 推出全屏
				this.player.isFull = false;
			}
		});
	},
	components: {
		CommonPopper
	},
	beforeDestroy() {
		this.destroy();
	},
	destroyed() {
		window.removeEventListener("beforeunload", () => {});
	}
};
</script>
<style lang="scss" scoped="video_wrapper">
i {
	cursor: pointer;
	color: #fff;
	font-size: 20px;
	margin: 0 5px;
}
.video_wrapper {
	background-color: #000000;
	height: 100%;
	width: 100%;
	min-height: 260px;
	.video_content {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		.video_header {
			background-color: rgba($color: #fff, $alpha: 0.2);
			color: #ffffff;
			height: 40px;
			position: relative;
			.video_title {
				font-weight: 600;
				font-size: 18px;
				line-height: 40px;
				text-align: center;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.video_close {
				color: #fff;
				position: absolute;
				right: 10px;
				top: 10px;
				cursor: pointer;
			}
		}
		.video_info {
			position: absolute;
			display: flex;
			width: 100%;
			justify-content: space-between;
			color: #fff;
			z-index: 999;
			font-size: 14px;
			padding: 0 10px;
			.video_info_left {
				text-align: left;
				.video_speed {
				}
			}
			.video_info_right {
				text-align: right;
				.video_network {
				}
			}
		}
		.video_container {
			flex-grow: 1;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			overflow: hidden;
			// transform: rotate(0.000001deg);
			video {
				display: block;
				width: 100%;
				height: 100%;
				max-height: 100%;
				object-fit: fill;
			}
			.video_loading,
			.video_tips {
				background-color: rgba($color: #000000, $alpha: 0.6);
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				display: flex;
				justify-content: center;
				flex-direction: column;
				h2 {
					color: #fff;
					text-align: center;
				}
			}
		}
		.video_control {
			height: 45px;
			background-color: rgba($color: #fff, $alpha: 0.2);
			position: relative;
			&.line-page {
				height: 20px;
				background-color: transparent;
			}
			.control_progress {
				position: absolute;
				top: -17px;
				left: 0;
				right: 0;
				overflow: hidden;
				padding: 0 10px;
				& ::v-deep .el-slider__runway {
					height: 1px;
					.el-slider__button-wrapper {
						top: -17px;
					}
					.el-slider__bar {
						height: 1px;
					}
				}
			}
			.control_func {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 100%;
				.control_left {
					color: #fff;
					margin-left: 10px;
				}
				.control_right {
					.el-button {
						color: #fff;
						font-size: 16px;
					}
					ul {
						padding-inline-start: 0px !important;
					}
					ul > li {
						list-style: none;
						line-height: 36px;
						padding: 0 5px;
						margin: 0;
						font-size: 14px;
						color: #606266;
						cursor: pointer;
						outline: none;
					}

					ul > li:hover {
						background-color: #ccffff;
					}
				}
			}
		}
	}
	.video_unSupport {
		text-align: center;
		margin: auto 0;
		color: #fff;
	}
}
</style>
